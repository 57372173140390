import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../asset/css/RestaurantDash.css";



const RestaurantDash = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // New state for restaurant status
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [restaurantData, setRestaurantData] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState({});



  // Fetch Restaurant Status
  const fetchRestaurantStatus = async () => {
    const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
    console.log("Logged In Partner ID:", loggedInPartnerId);
    try {
      const restaurantPartnerId = localStorage.getItem("restaurantPartnerId"); // Fetch ID
      console.log(restaurantPartnerId);
      if (!restaurantPartnerId) {
        setErrorMessage("No restaurant partner logged in.");
        return;
      }

      // Fetch status from backend

      const response = await axios.get("https://hungreli.com/api/getresturentstatus.php", {
        params: { restaurantPartnerId: loggedInPartnerId }, // Pass partner ID as query parameter
      });

      if (response.data.status === "success") {
        setRestaurantData(response.data); // Set data when successful
      } else {
        setErrorMessage("Failed to fetch restaurant status.");
      }
    } catch (err) {
      setErrorMessage(err.response?.data?.message || "Error fetching restaurant status.");
    }
  };

  const fetchOrderData = async () => {
    const resPartnerId = localStorage.getItem("restaurant_partner_id");
    console.log("Logged In Partner ID:", resPartnerId);
    try {
      const response = await axios.get(
        `https://hungreli.com/api/fetchOrdersAndUpdate.php?restaurant_partner_id=${resPartnerId}`
      );

      if (response.status === 200 && response.data.status === "success") {
        setOrderData(response.data.data); // Set fetched order data
      } else {
        setErrorMessage("Failed to fetch orders.");
      }
    } catch (err) {
      setErrorMessage(err.response?.data?.message || "Error fetching orders.");
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    const resPartnerstatus = localStorage.getItem("restaurant_partner_id");
    console.log("Logged In Partner ID:", resPartnerstatus);
    try {
      const response = await axios.post(
        `https://hungreli.com/api/fetchOrdersAndUpdate.php?restaurant_partner_id=${resPartnerstatus}`,
        {
          orderId,
          status: newStatus,
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        alert("Order status updated successfully!");
        fetchOrderData(); // Refresh the order data
      } else {
        alert("Failed to update order status.");
      }
    } catch (err) {
      alert(err.response?.data?.message || "Error updating order status.");
    }
  };


  useEffect(() => {
    fetchRestaurantStatus();

    // Polling every 10 seconds for updates (or use WebSocket for real-time updates)
    const interval = setInterval(fetchRestaurantStatus, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchOrderData();

    // Polling every 10 seconds for updates (or use WebSocket for real-time updates)
    const interval = setInterval(fetchOrderData, 10000);
    return () => clearInterval(interval);
  }, []);



  const handleViewProfile = () => {
    navigate(""); // Navigate to the AdminProfile page
    setDropdownVisible(!dropdownVisible);
  };

  const handleRegisterFood = () => {
    navigate("/restaurantFood"); // Navigate to the RestaurantFood registration page
  };

  const handlereturentlist = () => {
    navigate("/restaurantlistpage"); // Navigate to the RestaurantFood registration page
  };

  const handleLogout = () => {
    // Clear any necessary session or token
    localStorage.removeItem("authToken"); // Example of token removal
    navigate("/home"); // Redirect to Home.js
  };

  return (
    <div className="res-dashboard">
      {/* Header Section */}
      <div className="dashboard-header">
        <h1 className="dashboard-heading">Restaurant Dashboard</h1>
        {/* Displaying the status */}
        <div className="profile-dropdown">
          <button className="view-profile-btn" onClick={handleViewProfile}>
            View Profile
          </button>
        </div>
        {dropdownVisible && (
          <div className="dropdown-menu">
            <button className="logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>

       {/* Orders Table */}
       <div className="orders-table-container">
        {errorMessage ? (
          <p className="errorMessage">{errorMessage}</p>
        ) : orderData && orderData.length > 0 ? (
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Address</th>
                  <th>Delivery Time</th>
                  <th>Tip</th>
                  <th>Total</th>
                  <th>Payment Method</th>
                  <th>Order Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {orderData.map((order) => (
                  <tr key={order.orderId}>
                    <td>{order.orderId}</td>
                    <td>{order.address}</td>
                    <td>{order.deliveryTime}</td>
                    <td>{order.tip}</td>
                    <td>{order.total}</td>
                    <td>{order.paymentMethod}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.status}</td>
                    <td>
                      <select
                        value={statusUpdate[order.orderId] || order.status}
                        onChange={(e) =>
                          setStatusUpdate({
                            ...statusUpdate,
                            [order.orderId]: e.target.value,
                          })
                        }
                      >
                        <option value="Pending">Pending</option>
                        <option value="Preparing">Preparing</option>
                        <option value="Out for Delivery">Out for Delivery</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                      <button
                        onClick={() =>
                          handleStatusChange(order.orderId, statusUpdate[order.orderId] || order.status)
                        }
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>Food Name</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>In Stock</th>
                            <th>Item Price</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.foodName}</td>
                              <td>{item.category}</td>
                              <td>{item.description}</td>
                              <td>{item.inStock ? "Yes" : "No"}</td>
                              <td>{item.itemPrice}</td>
                              <td>{item.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No orders found.</p>
        )}
      </div>

      {/* Status Table */}
      <div className="status-table">
        {errorMessage ? (
          <p className="errorMessage">{errorMessage}</p>
        ) : restaurantData ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{restaurantData.restaurantPartnerstatus}</td>
                <td>{restaurantData.statusrestaurant}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </div>


      {/* New Container with Flexbox */}
      <div className="res-container">
        {/* First Box: Registration of Food Items */}
        <div className="food-registration-box">
          <h2>Registration of Food Items</h2>
          <p>
            Register the food items offered by the restaurant. You can select
            food categories, provide pricing details, discounts, and upload
            multiple images for each food item. This will help in managing the
            menu effectively.
          </p>
        </div>

        {/* Second Box: Register Food Button */}
        <div className="register-food-box">
          <h3>Register Food by Restaurant</h3>
          <button className="register-food-btn" onClick={handleRegisterFood}>
            Register Food
          </button>
        </div>
        <div className="register-food-box">
          <h3>Register List</h3>
          <button className="register-food-btn" onClick={handlereturentlist}>
            Register Food
          </button>
        </div>
        {/* About Section */}
        <div className="about-section mt-4">
          <p>
            Our platform is dedicated to connecting customers with the best local restaurants
            and ensuring timely delivery with the help of our skilled delivery personnel.
            We aim to create a seamless dining experience from order to delivery.
          </p>
        </div>
      </div>
    </div >




  );
};

export default RestaurantDash;
