import React from "react";
import '../../asset/css/TermsAndConditions.css';

const Refundpolicy = () => {
    // const [activeSection, setActiveSection] = useState('terms');


    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Cancellation and Refund Policy for Hungreli</h1>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <strong>
                        Welcome to Hungreli! Please read these terms and conditions carefully before using our services. By accessing or using the Hungreli platform, you agree to be bound by these terms. If you do not agree to any part of these terms, you may not use our services.
                    </strong>
                </div>
                
                    <>
                        <h2 id="refund-policy">Cancellation and Refund Policy</h2>
                        <ul>
                            <li><p>Users may cancel orders before the restaurant partner starts preparing the food. Once preparation begins, cancellations are not permitted.</p></li>
                            <li><p>Refunds will be processed based on the specific circumstances and at Hungreli's discretion.</p></li>
                            <li><p>If an order is canceled by Hungreli, users will receive a full refund.</p></li>
                        </ul>

                        <h2>User Responsibilities</h2>
                        <ul>
                            <li><p>Ensure the accuracy of delivery details when placing orders.</p></li>
                            <li><p>Refrain from engaging in abusive or fraudulent activities on the platform.</p></li>
                            <li><p>Use the platform solely for lawful purposes.</p></li>
                        </ul>

                        <h2>Liability and Indemnity</h2>
                        <p>Hungreli is not liable for:</p>
                        <ul>
                            <li>The quality or safety of food and beverages.</li>
                            <li>Delays in delivery caused by unforeseen circumstances.</li>
                            <li>Any direct, indirect, or incidental damages arising from the use of the platform.</li>
                        </ul>
                        <p>Users agree to indemnify Hungreli against any claims or damages resulting from their misuse of the platform.</p>
                    </>

               
                
                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us:</p>
                <p><b>Email:</b> <a href="mailto:info.hungreli.food@gmail.com">info.hungreli.food@gmail.com</a></p>
                <p><b>Postal Address:</b> 1202, G square business park opp, Sanpada Railway Station Road, Sector 30A, Vashi, Navi Mumbai, Maharashtra - 400703</p>
                <p style={{ marginBottom: '100px' }}><b>Phone Number:</b> +91-9004656560</p>
            </div>
        </div>
    );
};

export default Refundpolicy;
