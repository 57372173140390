import React from 'react';
import '../../asset/css/HelpCenter.css';

const HelpCenter = () => {
    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Instructions to Delete Your Account on Hungreli</h1>
                <ol>
                    <li>
                        <strong>Submit a Request for Account Deletion</strong>
                        <ul>
                            <li>Go to the <a href="/Support">Account Deletion Page</a>.</li>
                            <li>Go to the Help Center or Support Section.</li>
                            <li>Locate the option to Submit a Request for account deletion.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Provide Necessary Details</strong>
                        <ul>
                            <li>Fill out the account deletion request form with your registered email address, phone number, and any other required information.</li>
                            <li>Clearly mention that you wish to delete your account.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Follow the Instructions</strong>
                        <ul>
                            <li>After submitting your request, you will receive instructions via email.</li>
                            <li>Follow the steps provided to confirm your identity and proceed with the deletion process.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Approval Process</strong>
                        <ul>
                            <li>Once your request is submitted, it will be reviewed by the Hungreli support team.</li>
                            <li>Upon approval, your account will be deleted.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Data Deletion</strong>
                        <ul>
                            <li>After the approval, all your personal data, profile preferences, and transaction history will be deleted from the system.</li>
                            <li>Some data, such as financial records, will be retained for legal purposes as required by law.</li>
                        </ul>
                    </li>
                </ol>
                <p style={{ marginBottom: '100px' }}>
                    <strong>Note:</strong> The process may take a few days to complete, depending on the review and approval timeline.
                </p>
            </div>
        </div>
    );
};

export default HelpCenter;