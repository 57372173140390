import React from "react";
import { Link } from "react-router-dom";
import logo from "../../asset/images/logo.webp";

function App() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
    
        <Link className="navbar-brand" to="/">
        <a href="/" className="navbar-brand">
            <img src={logo} alt="Hungreli Logo" height="50" />
            Hungreli
          </a>
        </Link>
   
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/admin">
                Admin
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/restaurant-partner">
                Restaurant Partner
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/delivery-boy">
                Delivery Boy
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/terms-conditions">
                Terms & Conditions
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/refund-policy">
                Refund Policy
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/HelpCenter">
                Help Center
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Support">
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default App;
