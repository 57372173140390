import React from "react";
import '../../asset/css/TermsAndConditions.css';

const Privacypolicy = () => {
    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Privacy Policy for Hungreli</h1>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <strong>
                        Effective Date: 27th Jan 2025<br /><br />
                        This Privacy Policy ("Policy") describes how Hungreli ("we", "our", "us") collects, uses, shares, and protects your personal information when you use Hungreli ("the App"). This Policy applies to users of the App, and by using the App, you agree to the practices described herein.
                    </strong>
                </div>

                <h2 id="privacy-policy">1. Information We Collect</h2>
                <p>We collect a variety of information to provide, improve, and personalize your experience with our App. This information is categorized as follows:</p>

                <h3>1.1 Location Information</h3>
                <p>We collect location data to provide location-based services. This data helps us enhance your experience within the App by delivering relevant content or features based on where you are. We access the following types of location information:</p>
                <ul>
                    <li><strong>Approximate Location:</strong> We use your device's IP address or other data to infer your approximate location (e.g., city, region, country).</li>
                    <li><strong>Precise Location:</strong> With your explicit consent, we access your device's GPS or network-based location data, which allows us to determine your exact geographic coordinates (latitude and longitude).</li>
                </ul>
                <p>You can control the collection of location data at any time by disabling location permissions on your device. If you choose to deny location access, some features of the App may not work properly.</p>

                <h3>1.2 Personal Information</h3>
                <p>This includes any data that identifies or can be used to identify an individual. We may collect the following personal information when you use the App:</p>
                <ul>
                    <li><strong>Name:</strong> The name you provide when signing up or interacting with the App.</li>
                    <li><strong>Email Address:</strong> To communicate with you about your account or other services.</li>
                    <li><strong>User IDs:</strong> Unique identifiers used to track your interactions within the App.</li>
                    <li><strong>Address & Postal Code:</strong> If relevant to the services we provide (e.g., for delivery purposes).</li>
                    <li><strong>Phone Number:</strong> If required for account creation or communication purposes.</li>
                    <li><strong>Other Personal Information:</strong> Any additional personal information you provide, such as date of birth, gender identity, veteran status, etc.</li>
                </ul>

                <h3>1.3 Financial Information</h3>
                <p>We collect payment information when you make a purchase or engage in financial transactions within the App:</p>
                <ul>
                    <li><strong>Payment Information:</strong> This includes credit card numbers, billing address, and any other data necessary to process your transactions.</li>
                    <li><strong>Transaction History:</strong> We may collect information about your transactions to process payments, issue refunds, or provide customer support.</li>
                </ul>

                <h3>1.4 Messages</h3>
                <p>We collect and store certain communication data to provide better customer support or ensure the functionality of our services:</p>
                <ul>
                    <li><strong>Emails:</strong> Messages sent to us regarding your account or app-related issues.</li>
                    <li><strong>SMS:</strong> Text messages, if your use of the App requires SMS notifications or communications.</li>
                </ul>

                <h3>1.5 Media Files</h3>
                <p>We may collect photos or videos you upload within the App, particularly if the App has features related to media sharing or content creation.</p>
                <ul>
                    <li><strong>Photos:</strong> We access and store photos if they are uploaded to the App for user profiles, sharing, or other functionalities.</li>
                </ul>

                <h3>1.6 Contacts</h3>
                <p>We may access your contacts to help with specific features, like inviting friends, social sharing, or connecting with other users within the App.</p>
                <ul>
                    <li><strong>Contacts:</strong> We request permission to access your device's contacts to enable sharing, inviting others, or connecting with friends.</li>
                </ul>

                <h3>1.7 App Activity</h3>
                <p>We collect data about your interactions with the App, including:</p>
                <ul>
                    <li><strong>App Interactions:</strong> How you use the App, which features you access, and your activity within the App.</li>
                    <li><strong>User-generated Content:</strong> Any content you generate or upload, such as reviews, comments, or ratings.</li>
                    <li><strong>App Information & Performance:</strong> Technical data about how the App performs, such as crash logs, system errors, and device performance metrics.</li>
                    <li><strong>Crash Logs:</strong> We may collect logs related to app crashes, which help us debug and improve the app's performance.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>We use the information we collect for the following purposes:</p>
                <ul>
                    <li><strong>To Provide and Improve Our Services:</strong> We use your information to operate, maintain, and enhance the App, including customizing features for you.</li>
                    <li><strong>To Communicate with You:</strong> We may use your contact details to send important notifications, such as transactional messages or customer support.</li>
                    <li><strong>To Personalize Your Experience:</strong> Your data, including location information, helps us personalize content, recommendations, and services for you.</li>
                    <li><strong>For Analytics and Research:</strong> We may analyze usage patterns and app performance to improve functionality, fix bugs, and enhance the overall user experience.</li>
                    <li><strong>To Ensure App Security:</strong> We use the data to protect your account, ensure compliance with our terms of service, and prevent fraudulent activity.</li>
                    <li><strong>To Handle Customer Support:</strong> We collect communication data to resolve issues, answer your questions, and offer personalized assistance.</li>
                    <li><strong>For Legal and Compliance Purposes:</strong> We may need to use your information to comply with legal obligations or resolve disputes.</li>
                </ul>

                <h2>3. Data Sharing and Disclosure</h2>
                <p>We do not sell your personal data. However, we may share your information in the following ways:</p>

                <h3>3.1 Service Providers</h3>
                <p>We may share your data with trusted third-party service providers who help us perform essential functions such as payment processing, cloud storage, customer support, or app performance analysis. These service providers are contractually obligated to safeguard your data.</p>

                <h3>3.2 Legal Compliance</h3>
                <p>We may disclose your information when required by law or in response to a legal request, such as a subpoena or court order, or to protect our legal rights, users, and others.</p>

                <h3>3.3 Business Transfers</h3>
                <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</p>

                <h3>3.4 With Your Consent</h3>
                <p>We may share your information with other parties if you explicitly consent to such sharing (e.g., through social media features or third-party integrations).</p>

                <h2>4. Data Security</h2>
                <p>We implement industry-standard security measures to protect your personal data, including encryption and secure data storage practices. While we take all reasonable precautions to safeguard your data, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                <h2>5. Data Retention and Deletion</h2>
                <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Policy or as required by law. If you wish to request the deletion of your personal information, you may contact us at the contact details below, and we will take steps to remove your data.</p>

                <h2>6. Your Privacy Choices</h2>
                <ul>
                    <li><strong>Location Data:</strong> You can manage your location permissions directly from your device's settings to control whether or not we collect your location.</li>
                    <li><strong>Data Access and Deletion:</strong> You can request a copy of the data we hold about you, and you can also request its deletion by contacting us.</li>
                    <li><strong>Opt-out of Communications:</strong> You can opt out of marketing communications by following the unsubscribe instructions in any email we send you.</li>
                </ul>

                <h2>7. Children's Privacy</h2>
                <p>Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we discover that a child under 13 has provided us with personal data, we will delete that information immediately.</p>

                <h2>8. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on this page, and we will update the "Effective Date"" at the top of this Policy.</p>

              

                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us:</p>
                <p><b>Email:</b> <a href="mailto:info.hungreli.food@gmail.com">info.hungreli.food@gmail.com</a></p>
                <p><b>Postal Address:</b> 1202, G square business park opp, Sanpada Railway Station Road, Sector 30A, Vashi, Navi Mumbai, Maharashtra - 400703</p>
                <p style={{ marginBottom: '100px' }}><b>Phone Number:</b> +91-9004656560</p>
            </div>
        </div>
    );
};

export default Privacypolicy;